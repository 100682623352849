@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.header a.active {
  color: rgb(255, 255, 255) !important;
  background-color: #111827 !important;
}
#test.active{
  color: rgb(255, 255, 255) !important;
  background-color: inherit !important;
}

.header .absolute a.active {
  background-color: rgb(243, 243, 243) !important;
  color: black !important;
}




/* README */

.readme textarea:focus {
  outline: none;
  box-shadow: rgba(82, 146, 255, 0.3) 0px 3px 8px;
}

.readme h1, .readme h2, .readme h3, .readme h4, .readme h5, .readme h6 {
  font-family: source sans pro,helvetica neue,Helvetica,Arial,sans-serif !important;
  font-feature-settings: 'dlig' 1,'liga' 1,'lnum' 1,'kern' 1 !important;
  font-style: normal !important;
  font-weight: 600 !important;
  margin-top: 0 !important;
}
.readme h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
.readme h1 {
  font-size: 2.0571429rem;
  margin-bottom: 0.21999rem;
  padding-top: 0.78001rem;
}
.readme h4 {
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
.readme h4 {
  font-size: 1.5625rem;
  margin-bottom: 0.546865rem;
  padding-top: 0.453135rem;
}
.readme p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.readme blockquote p {
  font-size: 1rem;
  margin-bottom: 0.33999rem;
  font-style: italic;
  padding: 0.66001rem 1rem 1rem;
  border-left: 3px solid #a0aabf;
}
.readme p {
  padding-top: 0.66001rem;
  font-feature-settings: 'kern' 1,'onum' 1,'liga' 1;
  margin-top: 0;
}

@media screen and (min-width: 46.25em){
  .readme pre, .readme blockquote p {
    font-size: 1rem;
    padding-top: 0.66001rem;
  }
}
@media screen and (min-width: 46.25em){
  .readme blockquote p {
    margin-bottom: 0.33999rem;
  }
}

.readme ::marker {
  unicode-bidi: isolate;
  font-variant-numeric: tabular-nums;
  text-transform: none;
  text-indent: 0px !important;
  text-align: start !important;
  text-align-last: start !important;
}

.readme ul, .readme ol {
  margin-bottom: 0.83999rem;
  padding-top: 0.16001rem;
}

.readme ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.readme li {
  font-feature-settings: 'kern' 1,'onum' 1,'liga' 1;
  margin-left: 1rem;
}
.readme li {
  display: list-item;
  text-align: -webkit-match-parent;
}

@media screen and (min-width: 46.25em){
  .readme h3 {
    font-size: 1.6457143rem;
    margin-bottom: 0.07599rem;
    padding-top: 0.92401rem;
  }
} 

.readme code, .readme kbd, .readme pre, .readme samp {
  font-family: monospace,monospace;
}
.readme code, .readme kbd {
  font-size: 1em;
}
.readme code, .readme kbd {
  padding: 2px 4px;
}
.readme code {
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}
.readme code, .readme kbd {
  padding: 2px 4px;
}
.readme code, .readme kbd, .readme pre, .readme samp {
  font-family: monospace,monospace;
}
.readme code, .readme kbd {
  font-size: 1em;
}

.readme p, .readme pre {
    margin-bottom: 1.33999rem;
}
.readme pre {
  font-size: 1rem;
  padding: 0.66001rem 9.5px 9.5px;
  line-height: 2rem;
  background: linear-gradient(to bottom,#fff 0,#fff 0.75rem,#f5f7fa 0.75rem,#f5f7fa 2.75rem,#fff 2.75rem,#fff 4rem);
  background-size: 100% 4rem;
  border-color: #d3daea;
}
.readme pre {
  display: block;
  margin: 0 0 10px;
  word-break: break-all;
  word-wrap: break-word;
  color: #333;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* README */