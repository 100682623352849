.input_div {
  position: relative;
}
.input_div svg {
  position: absolute;
  top: 33px;
  right: 10px;
  cursor: pointer;
}

.error {
  border: 1.99px solid #dc2626 !important;
  border-image: initial !important;
  background: rgb(255, 244, 248) !important;
  --tw-ring-offset-shadow: none !important;
  --tw-ring-shadow: none !important;
  box-shadow: none !important;
}

.add_form input, .add_form select, .add_form textarea {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  width: 100%;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
  border-radius: 0.375rem/* 6px */;
  padding: 0.5rem/* 8px */;
}

.add_form input:focus, .add_form select:focus, .add_form textarea:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity)); 
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

@media (min-width: 640px) {
  .add_form input, .add_form select, .add_form textarea {
    font-size: 0.875rem/* 14px */;
    line-height: 1.25rem/* 20px */;
  }
}
.input_div input {
  padding-right: 44px;
}
.pointer {
  cursor: pointer;
}
.css-1ex1afd-MuiTableCell-root {
  padding: 8px !important;
}
.readme {
  max-height: 638px;
  overflow-y: auto;
}
.readme textarea{
  overflow-y: hidden;
}

@media screen and (max-width: 1024px){
  .readme textarea{
    min-height: 300px;
    overflow-y: auto;
  }
} 


/* SCROLL */

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(210, 210, 210); 
  border-radius: 4px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  --tw-bg-opacity: 1;
  background-color: rgb(84, 93, 104 , 0.6); 
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgb(27, 38, 52, 0.8);
}

/* SCROLL */

.avatar{
  font-size: 10px !important;
}